import React, { FC, useMemo } from 'react';
import classnames from 'classnames';
import { flattenDepth, isArray } from 'lodash';

import ProductСard from 'gatsby-theme-gaviscon/src/components/ProductCard';
import useScreenRecognition from 'gatsby-theme-gaviscon/src/hooks/useScreenRecognition';
import { IRecommendedProductsProps } from './model';
import './RecommendedProducts.scss';

const RecommendedProducts: FC<IRecommendedProductsProps> = ({
  bgColor = 'white',
  productLinks,
  products,
  title,
}) => {
  const { isMobile } = useScreenRecognition();
  const classes = classnames('gs-symptom-section__panel-block', 'recommended-products', {
    [`${bgColor}-default-bg`]: bgColor,
  });
  const unwrappedProducts = useMemo(
    () =>
      flattenDepth(
        productLinks?.map(({ properties: { link } }) =>
          link
            .map((_, index) => {
              const prodProps = (prodUrl) =>
                products.nodes.filter((item) => item.link === prodUrl)[0];
              const getProdById = (id) => ({
                isPare: id !== 0,
                ...prodProps(link[id].url),
              });

              if (isMobile) {
                return link[index + 1]
                  ? [getProdById(0), getProdById(1)]
                  : index === 1
                    ? null
                    : getProdById(0);
              }

              return getProdById(index);
            })
            .filter((_) => _)
        ) || [],
        1
      ),
    [products, isMobile]
  );

  return productLinks?.length ? (
    <div className={classes}>
      {title ? <h2 className="recommended-products__title">{title}</h2> : null}
      <div className="product-list">
        {unwrappedProducts.map(productItem => {
          const doubleProduct = [
            productItem && <ProductСard key={Math.random()} {...productItem[0]} />,
            productItem && <ProductСard key={Math.random()} {...productItem[1]} />,
          ];

          return isArray(productItem) ? (
            <div key={Math.random()} className="double-recommended-product">
              {doubleProduct}
            </div>
          ) : productItem ? (
            <ProductСard key={Math.random()} {...productItem} />
          ) : null;
        })}
      </div>
    </div>
  ) : null;
};
export default RecommendedProducts;
